import React, { useState, useEffect } from 'react';
import { FaBars, FaHome, FaUser } from 'react-icons/fa'; // Import icons
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLinkCreateAccount, NavBtnLinkSignIn, IconLink } from './NavbarElements';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth
import logoImage from '../../assets/sync-logo-removebg-preview.png'; // Import the logo image

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track user authentication status

  // Check scroll position for changing navbar background
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  // Scroll to top for logo click
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  // Check user authentication state
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true); // User is logged in
      } else {
        setIsAuthenticated(false); // No user logged in
      }
    });

    window.addEventListener('scroll', changeNav);

    return () => {
      window.removeEventListener('scroll', changeNav);
      unsubscribe(); // Clean up the auth listener
    };
  }, []);

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>
            <img src={logoImage} alt="Sync Logo" /> {/* Logo image */}
            
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to='mission' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                Mission
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='services' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                Services
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='discover' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                Discover
              </NavLinks>
            </NavItem>
            {isAuthenticated ? (
              // If the user is logged in, show Home and Profile icons
              <>
                <NavBtn>
                  <IconLink to='/home'>
                    <FaHome /> {/* Home icon */}
                  </IconLink>
                </NavBtn>
                <NavBtn>
                  <IconLink to='/profile'>
                    <FaUser /> {/* Profile icon */}
                  </IconLink>
                </NavBtn>
              </>
            ) : (
              // If the user is not logged in, show Sign in and Create account buttons
              <>
                <NavBtn>
                  <NavBtnLinkSignIn to='/login'>Sign in</NavBtnLinkSignIn>
                </NavBtn>
                <NavBtn>
                  <NavBtnLinkCreateAccount to='/signup'>Create account</NavBtnLinkCreateAccount>
                </NavBtn>
              </>
            )}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
