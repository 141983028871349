import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/TopBar.css'; // Import the CSS styles
import SyncLogo from '../assets/sync-logo-removebg-preview.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment, faCrown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Assuming you're using axios to make API requests
import GoPremiumButton from './GoPremiumButton'; // Import the GoPremiumButton component
import { getAuth } from 'firebase/auth';

const TopBar = () => {
  const [plan, setPlan] = useState(''); // State to store user's plan
  const auth = getAuth();

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const user = auth.currentUser; // Get current authenticated user
        if (user) {
          // Use the correct API route to get user by Firebase UID
          const response = await axios.get(`https://sync-api-troz.onrender.com/api/users/firebase/${user.uid}`); 
          
          // Assuming your API returns an object with a 'plan' field in the user's MongoDB data
          setPlan(response.data.plan); // Set the user's plan in state
        }
      } catch (error) {
        console.error('Error fetching user plan:', error);
      }
    };

    fetchUserPlan();
  }, [auth]);

  return (
    <div className="top-bar">
      <NavLink to="/home" className="logo-link">
        <img src={SyncLogo} alt="sync-logo" className="sync-logo-top-bar" />
      </NavLink>

      {/* Conditionally render the Go Premium button in the center if the user is on the freemium plan */}
      {plan === 'freemium' && (
        <div className="centered-go-premium">
          <GoPremiumButton className='go-premium-button'/>
        </div>
      )}

      <nav className="top-bar-nav">
        <NavLink to="/profile" activeClassName="active-link" className="nav-link">
          <FontAwesomeIcon icon={faUser} className="top-bar-icon" />
        </NavLink>
        <NavLink to="/messages" activeClassName="active-link" className="nav-link">
          <FontAwesomeIcon icon={faComment} className="top-bar-icon" />
        </NavLink>
      </nav>
    </div>
  );
};

export default TopBar;
