import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-2.svg'
import HHD from '../../images/hero.jpg'
import RPI from '../../images/rpi.jpg'



export const homeObjOne = 
{
    id: 'mission',
    topLine: 'get the pay you deserve',
    headLine: 'For $5/month, join a community where all revenue is pooled and distributed based on your share of platform streams.',
    description: 'Start getting paid for your talent!',
    alt: 'Guitarist',
    imgStart: true,
    img: HHD,  
    btnContent: 'Read our mission ->',
    to: '/mission'
}

export const homeObjTwo = 
{
    id: 'discover',
    topLine: 'Earn your share',
    headLine: 'Got 100 streams out of 1,000? You earn 10% of the pot. Simple, transparent, and built for creators.',
    description: 'Sync is made for artists.',
    alt: 'link',
    imgStart: false,
    img: RPI, 
    btnContent: 'Discover more',
    to: '/discover'
}


