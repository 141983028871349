import React, { useState } from 'react'
import { HeroContainer, HeroBg, VideoBg,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ArrowForward,
    ArrowRight,
    HeroContent
        } from './HeroElements'
import Hero from '../../images/hero4.jpg'
import { Button, ButtonRouter } from '../ButtonElement'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

  return (
    <HeroContainer id='home'>
        <HeroBg>
            <VideoBg src={Hero} alt='hero-image' />
        </HeroBg>
        <HeroContent>
            <HeroH1>
                Sync
            </HeroH1>
            <HeroP>
                Empowering Artists With Fair Pay
            </HeroP>
            <HeroBtnWrapper>
                <ButtonRouter style={{textDecoration: 'none'}} href="/signup" onMouseEnter={onHover} onMouseLeave={onHover}  >
                    Get started {hover ? <ArrowForward /> : <ArrowRight />}
                </ButtonRouter>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
