import React from 'react'
import { 
ServicesContainer,
ServicesH1,
ServicesWrapper,
ServicesCard,
ServicesIcon,
ServicesH2,
ServicesP } from './ServicesElements'
import Icon1 from '../../images/svg-2.svg'
import Icon2 from '../../images/svg-1.svg'
import Icon3 from '../../images/svg-3.svg'
import Icon4 from '../../images/svg-4.svg'

const services = () => {
  return (
    <ServicesContainer id='services'>
        <ServicesH1>
            Our Services
        </ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>
                    Music
                </ServicesH2>
                <ServicesP>
                    Upload your music and earn fair pay.
                </ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon4}/>
                <ServicesH2>
                    Messaging
                </ServicesH2>
                <ServicesP>
                    Accept stars from fellow artists to unlock secure chats and collaborate.
                </ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>
                    Collaboration
                </ServicesH2>
                <ServicesP>
                    Team up with artists in the DMs to create music and boost your streams.
                </ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServicesContainer>
  )
}

export default services
